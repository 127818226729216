<template>
  <td
    :class="`element-content cell_type_${cell.type}`"
    :data-clone-index="clone"
    :style="elementStyle"
  >
    <div class="external-content-loading">
      {{ lang('ChecklistBuilder.misc.loading_content') }}
    </div>
  </td>
</template>

<script>
// Noch nicht implementiert

export default {
  name: 'ChecklistCellTypeExternalContent',
  props: [
    'x',
    'y',
    'cell',
    'checklist',
    'state',
    'clone',
    'dataViewer',
    'data'
  ],
  computed: {
    elementStyle() {
      let style = false
      const color = this.dataViewer.temporary_background_color ||
      this.dataViewer.background_color ||
      this.cell.settings.cell_background_color
      if (color) {
        style = `background-color: ${color};`
      }
      return style
    },
    classes() {
      return this.state.classes
    },
    disabled() {
      return this.state.disabled
    }
  },
  methods: {
    lang(langKey) {
      const lang = this.data.ChecklistBuilder.getSetting('TRANSLATION')
      return lang(langKey)
    }
  }
}
</script>

<style>
  .checklist-viewer .element-content
  .external-content-loading {
    border: #999 solid 1px;
    border-radius: 3px;
    font-weight: bold;
    padding: 5px;
  }

  .checklist-viewer .element-content
  .external-content-loading-disabled {
    border: #999 solid 1px;
    border-radius: 3px;
    font-weight: bold;
    padding: 5px;
    color: #F00;
  }

  .checklist-viewer .element-content
  .cell_type_external_content table {
    table-layout: auto;
    height: auto;
  }
</style>
